<template>
  <div class="sidebar-menu">

    <router-link  to="/ak" tag="button">Аттестат аккредитации</router-link>
    <router-link  to="/obl" tag="button">Область аккредитации</router-link>
    <router-link  to="/sreg" tag="button">Свидетельство о регистрации</router-link>
<!--    <button class="btn" @click="$router.push('/certmng/f/t')">Порядок проведения работ</button>-->
    <router-link  to="/orgstr" tag="button">Порядок проведения работ</router-link>
<!--    <button class="btn" @click="$router.push('/form');foc" ref="myInput">Формы заявок</button>-->
    <router-link  to="/form" tag="button">Формы заявок</router-link>
    <!--    <router-link  to="/form" custom v-slot="{href, route, navigate, isActive, isExactActive}" >-->
<!--      <button :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" >-->
<!--        <a :href="href" @click="navigate">Формы заявок</a></button></router-link>-->
  </div>
</template>

<script>
export default {
  // name: "SidebarBlock"
}
</script>

<style scoped>
.sidebar-menu {
  /*grid-area: sidebar;*/
  /*align-self: start;*/
  display: flex;
  /*display: none;*/
  flex-flow: column nowrap;
  /*padding: 30px 0px;*/
  border:none;
  font-size: 16px;
}
.btn, a{
  text-shadow: 0px 1px 0px #000000;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: .7em 1em calc(.8em + 3px);
  border-radius: 3px;
  background-color: rgb(01, 127, 212);

  /*border-color: rgb(255,255,255,.3);*/
  box-shadow: 0 -1px rgb(255,255,255,.1) inset;
  transition: 0.2s;
  text-align: center;
  border: 1px solid rgb(255,255,255,.3) ;
  line-height: 1.5;
  }
.btn:hover,a:hover { background: rgba(255,255,255,.2);
  cursor: pointer;}

.router-link-active{
  border: solid 1px wheat;
  background: rgba(255,255,255,.2);
}
/*.router-link-active a {}*/
</style>