<template>
  <div class="item content">
    <div>
      <SidebarBlock2 />
    </div>
    <div class="right-block">
      <h4 class="title-mng">Эксперты:</h4>
    <div class="text-mng">
        <p>1. Степанов Александр Викторович</p>
        <p>2. Сидорин Александр Вячеславович</p>
        <p>3. Решетников Николай Витальевич</p>
        <p>4. Агупов Владимир Александрович</p>
        <p>5. Степанов Владимир Александрович</p>
        <p>6. Лопатин Юрий Всеволодович</p>
        <p>7. Степанова Ольга Владимировна</p>
        <p>8. Сидорин Александр Александрович</p>
        <p>9. Долгова Ольга Викторовна</p>
        <p>10. Стручков Игорь Юрьевич</p>
        <p>11. Ярушин Антон Сергеевич</p>
    </div>

    </div>
  </div>
</template>

<script>
import SidebarBlock2 from "@/components/SidebarBlock2.vue";

export default {
  name: "ExpertPage",
  components: {SidebarBlock2}
}
</script>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px 1fr;
  word-wrap: break-word;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);
}
.right-block{
  display: flex;
  flex-direction: column;
  /*justify-content: flex-start;*/
  align-items: center;
  border-left: 1px solid rgb(255,255,255,.4);
}

.title-mng{
  /*padding: 0px 20px;*/
 /*align-items: flex-start;*/
 /* order: 1;*/
 /* align-self: center;*/
 /* border: 1px solid red;*/

}
.text-mng{
  max-width: 1000px;
  /*border: 1px solid red;*/
  /*align-self: center;*/
  /*border: 1px solid red;*/
}
p {
  /*padding-left: 60px;*/
  line-height: 1.5;
  /*padding-right: 50px;*/
}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr !important;
  }
}

</style>